html,
body {
    height: 100%;
}

.mat-icon {
    overflow: inherit !important;
}

.mdc-tab--active {
    font-weight: 600 !important;
}

.mdc-tab-indicator__content {
    border-width: 1px !important;
}